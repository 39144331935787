import React, { useContext, useState } from 'react';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { isAbortError } from '../../utils';
import PageHeader from '../../ui/PageHeader';
import Label from '../../ui/Label';
import Select from '../../ui/Select';
import styled from 'styled-components';
import Input, { InputIcon, InputIconCntnr } from '../../ui/Input';
import Button from '../../ui/Button';
import theme from '../../ui/theme';
import LoaderBarContext from '../../ui/useLoaderBar';
import ClearIcon from '@mui/icons-material/Clear';
import Checkbox from '../../ui/Checkbox';
import { SwitchDouble } from '../../ui/Switch';
import { alphabeticSort } from '../../interventions/utils';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  gap: 3rem;
`;

const PosRouteCard = styled.div`
  header {
    height: 40px;
    background-color: #dce8ff;
    width: 100%;
    border-right: unset !important;

    h4 {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 2rem;
    }
  }

  .pos_route_card_content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    border-right: 2px dashed #dce8ff;
  }
`;

const PosologyInputs = styled.div`
  display: grid;
  align-items: end;
  grid-template-columns: 30% 65%;
  gap: 5%;
`;

const DeleteCol = styled.td`
  span,
  td {
    font-size: 0.6rem;
    color: darkgray;
    display: flex;
    justify-content: end;
    align-items: center;
  }
`;

const Table = styled.div`
  padding-left: 3rem;
  border-left: 2px dashed #dce8ff;

  table {
    width: 100%;

    thead {
      margin-bottom: 0.5rem;
    }

    .clear-icon {
      cursor: pointer;
      color: ${theme.red};
    }

    tr {
      border-bottom: 2px solid ${theme.blueBilan};

      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const CustomButtonBar = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 4%;

  button {
    max-height: unset !important;
  }
`;

export default function CreateOrUpdateInjection({ match, router }) {
  const controller = new AbortController();
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  let [schemas, setSchemas] = useState([]);

  let drugEditName = null;
  if (typeof match.location.query.drug !== 'undefined') {
    drugEditName = match.location.query.drug;
  }

  let [medicationSolute, setMedicationSolute] = useState(drugEditName);
  const [isSol, setIsSol] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [unit, setUnit] = useState(null);
  const [drugRoute, setDrugRoute] = useState(null);
  const [quantityMaxi, setQuantityMaxi] = useState(null);
  const [nbBoli, setNbBoli] = useState(null);
  const [boli, setBoli] = useState(null);
  const [quantityMaxiIncludingBoli, setQuantityMaxiIncludingBoli] = useState(null);
  const [injComment, setInjComment] = useState('');

  const { isLoading: medicationsIsLoading, data: medications } = useQuery(
    ['medicationsList'],
    async () => {
      return await http
        .get(`medications.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.data.map(med => {
            return {
              value: med.name,
              label: med.name,
            };
          });
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des médicaments');
          throw error;
        });
    },
    { cacheTime: 0 },
  );

  const { isLoading: solutesIsLoading, data: solutes } = useQuery(
    ['solutesList'],
    async () => {
      return await http
        .get(`parameterized_lists.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.data.find(s => s.name === 'solutes')?.items.map(sol => {
            return {
              value: sol.value,
              label: sol.value,
            };
          }) ?? [];
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des solutés');
          throw error;
        });
    },
    { cacheTime: 0 },
  );

  const { isLoading: drugRoutesIsLoading, data: drugRoutes } = useQuery(
    ['drugRoutesList'],
    async () => {
      return await http
        .get(`drug_routes.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return alphabeticSort(
            res.drug_routes.map(route => {
              return {
                value: route.code,
                label: route.name,
              };
            }),
            (value) => value.label,
          );
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des voies d\'administrations');
          throw error;
        });
    },
    { cacheTime: 0 },
  );

  const addInjection = () => {
    schemas.push({
      drug: drugEditName ?? medicationSolute,
      quantity: quantity,
      quantity_maxi: quantityMaxi,
      unit: unit.value,
      unit_numerator: unit.numerator,
      unit_denominator: unit.denominator,
      flow_rate: unit.flowRate,
      weight_dependant: unit.weightDependant,
      drug_route_name: drugRoute.label,
      drug_route_code: drugRoute.value,
      can_boli: boli,
      boli_maxi: nbBoli,
      quantity_maxi_including_boli: quantityMaxiIncludingBoli,
      comment: injComment,
      protocol_schema_ids: [],
    });

    setQuantity('');
    setUnit(null);
    setQuantityMaxi('');
    setDrugRoute(null);
    setBoli(null);
    setNbBoli('');
    setQuantityMaxiIncludingBoli('');
    setInjComment('');
  };

  const canNotAdd = () => {
    return quantity === null || unit === null || drugRoute === null;
  };

  const { isLoading: unitsIsLoading, data: units } = useQuery(
    ['unitsList'],
    async () => {
      return await http
        .get(`units.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return alphabeticSort(
            res.units.map(unit => {
              return {
                value: unit.name,
                label: unit.name,
                numerator: unit.numerator,
                denominator: unit.denominator,
                flowRate: unit.flow_rate,
                weightDependant: unit.weight_dependant,
              };
            }),
            (value) => value.label,
          );
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des unités');
          throw error;
        });
    },
    { cacheTime: 0 },
  );

  const canNotCreate = () => {
    return medicationSolute === null || schemas === null || schemas?.length === 0;
  };

  const create = async () => {
    let newSchemas = [];
    schemas.forEach(schema => {
      if (isEditable(schema)) newSchemas.push(schema);
    });
    try {
      await http.post(`injection_schema.json`, {
        json: { drug: drugEditName ?? medicationSolute, solute: isSol, injection_schemas: newSchemas },
      });
      router.push('/gestionnaire/injections/');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(
        'Impossible de créer ce schéma d\'injection. Vérifiez votre connexion internet ou contactez l\'assistance Syopé.',
      );
    } finally {
      setLoaderBar(false);
    }
  };

  const clearSchema = (injectionIdx) => {
    schemas.splice(injectionIdx, 1);
    setSchemas([...schemas]);
  };

  const { data: injectionSchemas } = useQuery(
    ['injectionSchemas'],
    async () => {
      return await http
        .get(`injection_schemas.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          if (drugEditName) {
            let injection_schemas = res.drugs?.find(it => it.drug === drugEditName)?.injection_schemas;
            setIsSol(injection_schemas?.every(s => s.solute));
            if (injection_schemas?.length > schemas?.length) {
              setSchemas(injection_schemas ?? []);
            }
          }
          return res.drugs;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des schémas d\'injections');
          throw error;
        });
    },
    { cacheTime: 0 },
  );

  const onDrugChange = value => {
    setMedicationSolute(value);
    setSchemas(injectionSchemas?.find(it => it.drug === value)?.injection_schemas ?? []);
  };

  const onBoliChange = () => {
    setBoli(!boli);
  };

  const isEditable = (schema): boolean => {
    return schema?.protocol_schema_ids === null || schema?.protocol_schema_ids?.length === 0;
  };

  const getBolusInfos = (schema) => {
    const boliMax = (schema.boli_maxi === null || schema.boli_maxi === '')
    && (schema.quantity_maxi_including_boli === null || schema.quantity_maxi_including_boli === '')
      ? ''
      : schema.boli_maxi && schema.quantity_maxi_including_boli
        ? `(max. ${schema.boli_maxi} boli ou ${schema.quantity_maxi_including_boli} ${schema.unit_numerator})`
        : schema.boli_maxi
          ? `(max. ${schema.boli_maxi} boli)`
          : `(max. ${schema.quantity_maxi_including_boli} ${schema.unit_numerator})`;
    return schema.can_boli ? `Bolus autorisé ${boliMax}` : 'Bolus interdit';
  };

  const destroy = async () => {
    try {
      await http.delete(`injection_schemas.json`, { json: { drug: drugEditName } });
      router.push('/gestionnaire/injections/');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(
        'Impossible de supprimer ce schéma d\'injection. Vérifiez votre connexion internet ou contactez l\'assistance Syopé.',
      );
    } finally {
      setLoaderBar(false);
    }
  };

  const validateBtn = <Button disabled={canNotCreate()} onClick={() => create()}>
    {drugEditName ? 'Modifier le schéma d\'injection' : 'Créer le schéma d\'injection'}
  </Button>;

  return (
    <>
      <PageHeader style={{ 'margin-bottom': '1rem', 'display': 'flex', 'justify-content': 'space-between' }}>
        {drugEditName ? (<h1>Modifier un schéma d'injection</h1>) : (<h1>Créer ou modifier un schéma d'injection</h1>)}
        <SwitchDouble labels={['Médicament', 'Soluté']} switchValue={isSol} onChange={() => setIsSol(!isSol)} />
      </PageHeader>

      <Content>
        {!medicationsIsLoading && medications && ((!isSol &&
            <div>
              <Label htmlFor='filter-injection-input-drug'>Sélectionnez une drogue</Label>
              <Select
                id='filter-injection-input-drug'
                isSearchable={true}
                isClearable={true}
                options={medications}
                isDisabled={drugEditName !== null}
                value={medications.find(it => it.value === medicationSolute)}
                onChange={ev => onDrugChange(ev?.value ?? '')}
                placeholder='Sélectionnez une drogue'
              />
            </div>
          ) || (isSol &&
            <div>
              <Label htmlFor='filter-injection-input-sol'>Sélectionnez un soluté</Label>
              <Select
                id='filter-injection-input-sol'
                isSearchable={true}
                isClearable={true}
                options={solutes}
                isDisabled={drugEditName !== null}
                value={solutes.find(it => it.value === medicationSolute)}
                onChange={ev => onDrugChange(ev?.value ?? '')}
                placeholder='Sélectionnez un soluté'
              />
            </div>
          )
        )}
        {medicationSolute && (
          <>
            <PosRouteCard>
              <header><h4>Posologie / Voie d'administration / Bolus</h4></header>
              <div className={'pos_route_card_content'}>
                <PosologyInputs>
                  <div>
                    <Label htmlFor='dose-input'>Dose</Label>
                    <Input
                      id='dose-input'
                      type='number'
                      inputMode='numeric'
                      placeholder='Dose'
                      value={quantity}
                      onChange={ev => setQuantity(ev.target.value)}
                    />
                  </div>
                  <div>
                    <Label htmlFor='unit-input'>Sélectionnez une unité</Label>
                    <Select
                      id='unit-input'
                      isSearchable={true}
                      isClearable={true}
                      placeholder='Unité'
                      options={units}
                      value={unit}
                      onChange={ev => setUnit(ev)}
                    />
                  </div>
                </PosologyInputs>
                <div>
                  <Label htmlFor='drug-routes-input'>Sélectionnez une voie d'administration</Label>
                  <Select
                    id='drug-routes-input'
                    isSearchable={true}
                    isClearable={true}
                    options={drugRoutes}
                    value={drugRoute}
                    onChange={ev => setDrugRoute(ev)}
                    placeholder="Voie d'administration"
                  />
                </div>
                {unit !== null && !unit.flowRate && !isSol && (
                  <>
                    <PosologyInputs>
                      <div><Checkbox checked={boli} onChange={onBoliChange} label='Bolus autorisé' /></div>
                      <div>
                        <Label htmlFor='boli-maxi-input'>Nombre de boli maximum</Label>
                        <Input
                          id='boli-maxi-input'
                          type='number'
                          inputMode='numeric'
                          placeholder='Nombre de boli maximum'
                          value={nbBoli}
                          onChange={ev => setNbBoli(ev.target.value)}
                        />
                      </div>
                    </PosologyInputs>
                    <PosologyInputs>
                      <div>
                        <Label htmlFor='quantity-maxi-input'>Dose maximum par bolus</Label>
                        <InputIconCntnr>
                          <InputIcon className='grey'>{unit?.numerator ?? '-'}</InputIcon>
                          <Input
                            id='quantity-maxi-input'
                            type='number'
                            inputMode='numeric'
                            placeholder='Dose maximum (par bolus)'
                            value={quantityMaxi}
                            onChange={ev => setQuantityMaxi(ev.target.value)}
                          />
                        </InputIconCntnr>
                      </div>
                      <div>
                        <Label htmlFor='quantity-maxi-including-bolus-input'>Dose totale maximum (boli compris)</Label>
                        <InputIconCntnr>
                          <InputIcon className='grey'>{unit?.numerator ?? '-'}</InputIcon>
                          <Input
                            id='quantity-maxi-including-bolus-input'
                            type='number'
                            inputMode='numeric'
                            placeholder='Dose totale maximum (boli compris)'
                            value={quantityMaxiIncludingBoli}
                            onChange={ev => setQuantityMaxiIncludingBoli(ev.target.value)}
                          />
                        </InputIconCntnr>
                      </div>
                    </PosologyInputs>
                  </>
                )}
                <div>
                  <Label htmlFor='comment-input'>Commentaire</Label>
                  <Input id='comment-input' value={injComment}
                         onChange={ev => setInjComment(ev.target.value)} />
                </div>
                <Button disabled={canNotAdd()} onClick={() => addInjection()}>Ajouter</Button>
              </div>
            </PosRouteCard>

            <Table>
              <table>
                <thead>
                <tr>
                  <th>Posologie</th>
                  <th>Voies d'administration</th>
                  <th>Bolus</th>
                  <th>Commentaire</th>
                </tr>
                </thead>
                {schemas?.map((schema, idx) => (
                  <tr key={idx}>
                    <td>{schema.quantity_maxi
                      ? `${schema.quantity} ${schema.unit} (max. ${schema.quantity_maxi} ${schema.unit_numerator})`
                      : `${schema.quantity} ${schema.unit}`}</td>
                    <td>{schema.drug_route_name}</td>
                    <td>{getBolusInfos(schema)}</td>
                    <td>{schema.comment}</td>
                    <DeleteCol>
                      {isEditable(schema)
                        ? (<div className={'clear-icon'}><ClearIcon className='warn' onClick={() => clearSchema(idx)} />
                        </div>)
                        : (<span>Affecté à un schéma de protocole</span>)}
                    </DeleteCol>
                  </tr>
                ))}
              </table>
            </Table>
          </>
        )}

        {drugEditName && (
          <CustomButtonBar style={{ 'margin-top': '2rem' }}>
            <Button className={'warn'} onClick={() => destroy()}>Supprimer</Button>
            {validateBtn}
          </CustomButtonBar>
        ) || validateBtn}
      </Content>
    </>
  );
}
