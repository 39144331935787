import { Route } from "found";
import React from "react";

import SignIn from "./screens/SignIn";
import Parsys from "./screens/Parsys";
import PasswordChallenge from "./screens/PasswordChallenge";
import PasswordReset from "./screens/PasswordReset";
import NfsaveAadTest from "./screens/NfsaveAadTest";
import AmaDemo from "./screens/AmaDemo";
import PhoneChallenge from "./screens/PhoneChallenge";
import PhoneReset from "./screens/PhoneReset";
import ResetEmpty from "./screens/ResetEmpty";
import NfsaveAadTest2 from "./screens/NfsaveAadTest2";
import CustomConnect from './screens/CustomConnect';

export const routes = (
  <Route path="auth">
    <Route path="sign-in" Component={SignIn} />
    <Route path="parsys" Component={Parsys} />
    <Route path="sso-ama-demo" Component={AmaDemo} />
    <Route path="sso-nfsave-aad-test" Component={NfsaveAadTest} />
    <Route path="sso-nfsave-aad-test2" Component={NfsaveAadTest2} />
    {/**  CUSTOM CONNECT FOR AAD - ADFS - OIDC  **/}
    {/* AAD */}
    <Route path="sso-sdis35-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis35_aad',
        btnTxt: 'Se connecter avec SDIS 35 AzureAD',
    }} />
    <Route path="sso-samu37-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/samu37_aad',
        btnTxt: 'Se connecter avec SAMU 37 AzureAD',
    }} />
    <Route path="sso-sdis38-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis_38_aad',
        btnTxt: 'Se connecter avec SDIS 38 AzureAD',
    }} />
    <Route path="sso-sdis47-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis47_aad',
        btnTxt: 'Se connecter avec SDIS 47 AzureAD',
    }} />
    <Route path="sso-smur79fa-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/samu_79_aad',
        btnTxt: 'Se connecter avec AzureAD SMUR Faye L\'Abbesse',
    }} />
    <Route path="sso-sdis71-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis71_aad',
        btnTxt: 'Se connecter avec AzureAD SDIS 71',
    }} />
    <Route path="sso-sdis73-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis73_aad',
        btnTxt: 'Se connecter avec AzureAD SDIS 73',
    }} />
    <Route path="sso-sdis28-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis28_aad',
        btnTxt: 'Se connecter avec AzureAD SDIS 28',
    }} />
    <Route path="sso-samu83-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/samu83_aad',
        btnTxt: 'Se connecter avec SAMU 83 AzureAD',
    }} />
    <Route path="sso-chdraguignan-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/chdraguignan_aad',
        btnTxt: 'Se connecter avec intranet CH Draguignan',
    }} />
    <Route path="sso-chhyeres-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/chhyeres_aad',
        btnTxt: 'Se connecter avec intranet CH Hyères',
    }} />
    <Route path="sso-chibll-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/chibll_aad',
        btnTxt: 'Se connecter avec intranet CHI BLL',
    }} />
    <Route path="sso-chifsr-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/chifsr_aad',
        btnTxt: 'Se connecter avec intranet CHI FSR',
    }} />
    <Route path="sso-chsttropez-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/chsainttropez_aad',
        btnTxt: 'Se connecter avec intranet CH St-Tropez',
    }} />
    <Route path="sso-chtoulon-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/chtoulon_aad',
        btnTxt: 'Se connecter avec intranet CH Toulon',
    }} />
    <Route path="sso-chlaseynesurmer-aad" Component={CustomConnect} data={{
        aadUrl: '/omniauth/chlaseynesurmer_aad',
        btnTxt: 'Se connecter avec intranet CH la Seyne sur Mer'
    }} />
    {/* ADFS */}
    <Route path="sso-sdis49-adfs" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis49_adfs',
        btnTxt: 'Se connecter avec SDIS 49 ADFS',
    }} />
    <Route path="sso-sdis64-adfs" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis64_adfs',
        btnTxt: 'Se connecter avec SDIS 64 ADFS',
    }} />
    <Route path="sso-sdis85-adfs" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis85_adfs',
        btnTxt: 'Se connecter avec SDIS 85 ADFS',
    }} />
    {/* OIDC */}
    <Route path="sso-sdis37-oidc" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis37_oidc',
        btnTxt: 'Se connecter avec Intranet SDIS 37',
    }} />
    <Route path="sso-sdis83-oidc" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis83_oidc',
        btnTxt: 'Se connecter avec intranet SDIS 83',
    }} />
    <Route path="sso-sdis64-oidc" Component={CustomConnect} data={{
        aadUrl: '/omniauth/sdis64_oidc',
        btnTxt: 'Se connecter avec intranet SDIS 64',
    }} />
    {/**  END - CUSTOM CONNECT FOR AAD - ADFS - OIDC  **/}

    <Route path="forgot-password" Component={PasswordChallenge} data={{
      subTitle: "Mot de passe oublié ?",
      tabRow: true,
    }} />
    <Route path="password-reset/:token" Component={PasswordReset} />
    <Route path="password-reset" Component={ResetEmpty} />
    <Route path="password-reset-resend" Component={PasswordChallenge} data={{
      subTitle: "Vous permettra de recevoir un nouveau lien sur votre boîte mail.",
      tabRow: false,
    }} />
    <Route path="forgot-phone" Component={PhoneChallenge} data={{
      subTitle: "Mot de passe oublié ?",
      tabRow: true,
    }} />
    <Route path="phone-reset/:token" Component={PhoneReset} />
    <Route path="phone-reset" Component={ResetEmpty} />
    <Route path="phone-reset-resend" Component={PhoneChallenge} data={{
      subTitle: "Vous permettra de recevoir un nouveau code.",
      tabRow: false,
    }} />
  </Route>
);
