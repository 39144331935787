import React, { useEffect, useState } from 'react';
import http from '../../http';
import styled from 'styled-components';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const Container = styled.div`
  padding: 2rem;
`;

const OrganizationCard = styled.div`
  margin-bottom: 2rem;
  padding: 2rem;
`;

const OrganizationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const HospitalsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const HospitalCard = styled(Card)`
  padding: 1rem;
  background-color: #4e5154 !important;
  color: #fff !important;
`;

const HospitalContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const HospitalInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5rem;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
`;

interface Hospital {
  id: number;
  organization_id: number;
  nom: string;
  short_name: string;
  address: string;
  ville: string;
  departement: string;
  disabled_at: string | null;
  organization: {
    id: number;
    name: string;
    departement: string;
  };
}

interface GroupedHospitals {
  [organizationId: string]: {
    organizationName: string;
    hospitals: Hospital[];
  };
}

interface HospitalFormData {
  nom: string;
  short_name: string;
  address: string;
  ville: string;
  departement: string;
  disabled: boolean;
}

export default function Hospitals() {
  const [hospitals, setHospitals] = useState<GroupedHospitals>({});
  const [selectedHospital, setSelectedHospital] = useState<Hospital | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const [formData, setFormData] = useState<HospitalFormData>({
    nom: '',
    short_name: '',
    address: '',
    ville: '',
    departement: '',
    disabled: false,
  });

  useEffect(() => {
    loadHospitals();
  }, []);

  const loadHospitals = async () => {
    try {
      const response: Hospital[] = await http.get('hopital?department_only=true').json();
      const grouped = response.reduce((acc: GroupedHospitals, hospital: Hospital) => {
        const orgId = hospital.organization_id.toString();
        if (!acc[orgId]) {
          acc[orgId] = {
            organizationName: hospital.organization.name,
            hospitals: [],
          };
        }
        acc[orgId].hospitals.push(hospital);
        return acc;
      }, {});
      setHospitals(grouped);
    } catch (error) {
      console.error('Failed to load hospitals:', error);
    }
  };

  const handleEdit = (hospital: Hospital) => {
    setSelectedHospital(hospital);
    setFormData({
      nom: hospital.nom,
      short_name: hospital.short_name,
      address: hospital.address || '',
      ville: hospital.ville,
      departement: hospital.departement,
      disabled: !!hospital.disabled_at,
    });
    setDialogOpen(true);
  };

  const handleAdd = (organizationId: number) => {
    setSelectedHospital(null);
    setSelectedOrgId(organizationId);
    setFormData({
      nom: '',
      short_name: '',
      address: '',
      ville: '',
      departement: '',
      disabled: false,
    });
    setDialogOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (selectedHospital) {
        // Update existing hospital
        await http.put(`hopital/${selectedHospital.id}`, {
          json: {
            ...formData,
            disabled_at: formData.disabled ? new Date().toISOString() : null,
          },
        });
      } else if (selectedOrgId) {
        // Create new hospital
        await http.post('hopital', {
          json: {
            ...formData,
            organization_id: selectedOrgId,
            disabled_at: formData.disabled ? new Date().toISOString() : null,
          },
        });
      }
      await loadHospitals();
      setDialogOpen(false);
    } catch (error) {
      console.error('Failed to save hospital:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <Container>
      <Typography variant='h4' style={{ marginBottom: '2rem' }}>
        Services de santé
      </Typography>

      {Object.entries(hospitals).map(([orgId, { organizationName, hospitals }]) => (
        <OrganizationCard key={orgId}>
          <OrganizationHeader>
            <Typography variant='h6'>{organizationName}</Typography>
            <Button
              startIcon={<AddIcon />}
              variant='contained'
              color='primary'
              onClick={() => handleAdd(parseInt(orgId))}
            >
              Ajouter un service
            </Button>
          </OrganizationHeader>

          <HospitalsGrid>
            {hospitals.map(hospital => (
              <HospitalCard key={hospital.id}>
                <HospitalContent>
                  <HospitalInfo>
                    <Typography style={{ fontWeight: 'bold' }}>{hospital.nom}</Typography>
                    <Typography>{hospital.short_name}</Typography>
                    <Typography>
                      {hospital.ville} ({hospital.departement})
                    </Typography>
                    {hospital.address && <Typography>{hospital.address}</Typography>}
                    {hospital.disabled_at && <Typography color='error'>Désactivé</Typography>}
                  </HospitalInfo>
                  <IconButton onClick={() => handleEdit(hospital)}>
                    <EditIcon style={{ color: '#fff' }} />
                  </IconButton>
                </HospitalContent>
              </HospitalCard>
            ))}
          </HospitalsGrid>
        </OrganizationCard>
      ))}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth='sm' fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{selectedHospital ? 'Modifier le service' : 'Ajouter un service'}</DialogTitle>
          <DialogContent>
            <FormContent>
              <TextField name='nom' label='Nom' value={formData.nom} onChange={handleInputChange} fullWidth required />
              <TextField
                name='short_name'
                label='Nom court'
                value={formData.short_name}
                onChange={handleInputChange}
                fullWidth
                required
              />
              <TextField
                name='address'
                label='Adresse'
                value={formData.address}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                name='ville'
                label='Ville'
                value={formData.ville}
                onChange={handleInputChange}
                fullWidth
                required
              />
              <TextField
                name='departement'
                label='Département'
                value={formData.departement}
                onChange={handleInputChange}
                fullWidth
                required
              />
              <FormControlLabel
                control={<Checkbox name='disabled' checked={formData.disabled} onChange={handleInputChange} />}
                label='Désactivé'
              />
            </FormContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Annuler</Button>
            <Button type='submit' variant='contained' color='primary'>
              {selectedHospital ? 'Modifier' : 'Créer'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
}
