import { DateTime } from 'luxon';

export function getBit(n, k) {
  if (n == null) {
    return false;
  }
  return (n >> k) & 1;
}

/** Checks if the object has a valid number value */
export const hasData = o => {
  for (const v of Object.values(o)) {
    if (typeof v === 'number' && v > 0) {
      return true;
    }
  }
  return false;
};

/** Sort alphabetical */
export const alphabeticSort = (array, valueGetter: (any) => string) => {
  return array?.sort((left, right) => {
    const lhs = (valueGetter(left) || '').toUpperCase();
    const rhs = (valueGetter(right) || '').toUpperCase();
    return lhs > rhs ? 1 : -1;
  });
};

/** Sort date */
export const dateSort = (array, valueGetter: (any) => any) => {
  return array?.sort((left, right) => {
    let lhs = DateTime.fromISO(valueGetter(left)).toMillis();
    let rhs = DateTime.fromISO(valueGetter(right)).toMillis();
    if (isNaN(lhs)) lhs = 0;
    if (isNaN(rhs)) rhs = 0;
    return lhs < rhs ? 1 : -1;
  });
};

// dec2hex :: Integer -> String
export function dec2hex(dec) {
  return ('0' + dec.toString(16)).substr(-2);
}

// generateId :: Integer -> String
export function generateId(len) {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

/**
 * L'utilisateur fait-il partie de l'organisation Puy du Fou ?
 * Egalement activé pour les utilisateurs de l'organisation Syopé, à des fins de débogage.
 */
export const isPdf = () => {
  const currentUser = JSON.parse(localStorage.getItem('nfCurrentUser'));
  return [1, 38].includes(currentUser.organization_id);
};

/**
 * @return {string}
 */
export function ValueLabel({ value, labels }) {
  const tuple = labels.find(([v]) => value === v);
  return tuple != null ? tuple[1] : '';
}

export const MEDIC_UNITS = new Map([
  /** Attention ! En lien avec les listes d'unités admin et android
   * Admin -> listes.model.ts
   * Android -> SheetFactory.kt
   * **/
  [1, 'mg'],
  [2, 'g'],
  [3, 'mL'],
  [4, 'L/min'],
  [5, 'Dose(s)'],
  [6, 'Sachet(s)'],
  [7, 'Comprimé(s)'],
  [8, 'Ampoule(s)'],
  [9, 'Suppositoire(s)'],
  [10, 'NA'],
  [11, 'mg/h'],
  [12, 'gamma/kg/min'],
  [13, 'Unité Internationales (UI)'],
  [14, 'μg/h'],
  [15, 'UI/h'],
  [16, 'μg'],
  [17, 'mg/2ml'],
  [18, 'Bouffée(s)'],
]);

export const MEDIC_ROUTES = new Map([
  [0, 'Voie NR'],
  [1, 'Inhalé'],
  [2, 'Intramusculaire'],
  [3, 'Intranasal'],
  [4, 'Intrarectal'],
  [5, 'IV flash'],
  [6, 'IV lente'],
  [7, 'Per cutanée'],
  [8, 'Per OS'],
  [9, 'PSE'],
  [10, 'Sous-cutané'],
  [11, 'Sublinguale'],
]);

const NAMING_HS_DELETE = ` (PLUS EN SERVICE)`;
const NAMING_HS_A_SUPPR = 'Z - A SUPPRIMER';
const NAMING_A_SUPPR = 'Z - A SUPPRIMER';

export const healthStructureServiceData = (currentUser, hopitals, healthStructureId, enCours) => {
  const DEFAULT_DEPARTMENT = 35;
  const BORDERING = new Map([
    [1, [38, 73, 74, 39, 71, 69]],
    [2, [59, 80, 60, 77, 51, 8]],
    [3, [42, 71, 58, 18, 23, 63]],
    [4, [83, 6, 5, 26, 84, 13]],
    [5, [4, 26, 38, 73]],
    [6, [83, 4, 20]],
    [7, [48, 30, 84, 26, 38, 69, 43]],
    [8, [55, 51, 2, 59]],
    [9, [31, 11, 66]],
    [10, [89, 21, 52, 51, 77]],
    [11, [9, 31, 81, 34]],
    [12, [34, 30, 48, 15, 46, 82, 81]],
    [13, [30, 84, 83, 20]],
    [14, [27, 61, 50]],
    [15, [48, 43, 63, 19, 46, 12]],
    [16, [17, 79, 86, 87, 24]],
    [17, [85, 79, 16, 33]],
    [18, [36, 41, 45, 89, 58, 3, 23]],
    [19, [23, 87, 24, 46, 15, 63]],
    [20, [13, 83, 6]],
    [21, [10, 89, 58, 71, 39, 70, 52]],
    [22, [29, 56, 35]],
    [23, [87, 19, 63, 3, 18, 36]],
    [24, [17, 16, 87, 19, 46, 47, 33]],
    [25, [39, 70, 90]],
    [26, [84, 4, 5, 38, 69, 7]],
    [27, [76, 60, 95, 78, 28, 61, 14]],
    [28, [27, 78, 91, 45, 41, 72, 61]],
    [29, [22, 56]],
    [30, [34, 12, 48, 7, 26, 84, 13]],
    [31, [65, 32, 82, 81, 11, 9]],
    [32, [31, 65, 64, 40, 47, 82]],
    [33, [17, 24, 47, 40]],
    [34, [11, 81, 12, 30]],
    [35, [50, 53, 49, 44, 56, 22]],
    [36, [37, 41, 18, 23, 87, 86]],
    [37, [41, 36, 86, 49, 72]],
    [38, [5, 73, 1, 69, 7, 26, 5]],
    [39, [1, 71, 21, 70, 25]],
    [40, [33, 47, 32, 64]],
    [41, [72, 37, 36, 18, 45, 28]],
    [42, [43, 69, 71, 3, 63]],
    [43, [48, 7, 69, 42, 63, 15]],
    [44, [56, 35, 53, 49, 85]],
    [45, [91, 77, 28, 41, 18, 58, 89]],
    [46, [15, 19, 24, 47, 82, 12]],
    [47, [33, 40, 32, 82, 46, 24]],
    [48, [12, 30, 7, 43, 15]],
    [49, [44, 53, 72, 37, 86, 79, 85]],
    [50, [14, 61, 53, 35]],
    [51, [2, 77, 10, 52, 55, 8]],
    [52, [21, 70, 88, 55, 51, 10]],
    [53, [49, 72, 61, 50, 35, 44]],
    [54, [57, 67, 88, 55]],
    [55, [54, 88, 52, 51, 8]],
    [56, [29, 22, 35, 44]],
    [57, [67, 88, 54]],
    [58, [3, 71, 21, 89, 45, 18]],
    [59, [2, 80, 62, 8]],
    [60, [2, 80, 76, 27, 95, 77]],
    [61, [14, 27, 28, 72, 53, 50]],
    [62, [80, 59, 2]],
    [63, [15, 43, 42, 3, 23, 19]],
    [64, [40, 32, 65]],
    [65, [64, 32, 31]],
    [66, [9, 11]],
    [67, [68, 88, 54, 57]],
    [68, [90, 70, 88, 67]],
    [69, [1, 71, 42, 43, 7, 26, 38]],
    [70, [21, 39, 25, 90, 88, 52]],
    [71, [3, 42, 69, 1, 39, 21, 58]],
    [72, [28, 61, 53, 49, 37, 41]],
    [73, [74, 1, 38, 5]],
    [74, [73, 1]],
    [75, [92, 93, 94]],
    [76, [80, 60, 27]],
    [77, [89, 10, 51, 2, 60, 95, 91, 45]],
    [78, [95, 27, 28, 91, 92]],
    [79, [17, 85, 49, 86, 16]],
    [80, [62, 59, 2, 60, 76]],
    [81, [11, 34, 12, 82, 31]],
    [82, [32, 31, 81, 12, 46, 47]],
    [83, [13, 84, 4, 6, 20]],
    [84, [13, 4, 26, 7, 30]],
    [85, [44, 49, 79, 17]],
    [86, [36, 37, 49, 79, 16, 87]],
    [87, [23, 19, 24, 16, 86]],
    [88, [70, 90, 68, 67, 57, 54, 55, 52]],
    [89, [58, 21, 10, 77, 45]],
    [90, [25, 70, 88, 68]],
    [91, [45, 28, 77, 78, 92, 94]],
    [92, [75, 95, 78, 93]],
    [93, [92, 95, 94]],
    [94, [77, 75, 92, 93, 91]],
    [95, [78, 27, 60, 93, 92]],
  ]);

  function shiftDepartment(department, organizationDepartment) {
    if (department === organizationDepartment || department === 0) {
      return department;
    }
    const bordering = BORDERING.get(organizationDepartment) || [];
    if (bordering.includes(department)) {
      return department + 1000;
    }
    return department + 10000;
  }

  const organizationDepartment = currentUser?.organization?.departement ?? DEFAULT_DEPARTMENT;
  const healthStructures = [];
  const healthServicesByStructure = new Map();
  for (const h of hopitals) {
    if (h.disabled_at !== null) continue;

    if (!healthServicesByStructure.has(h.organization.id)) {
      healthStructures.push(h.organization);
      healthServicesByStructure.set(h.organization.id, []);
    }
    healthServicesByStructure.get(h.organization.id).push(h);
  }
  healthStructures.sort((lhs, rhs) => {
    const lhsDepartment = shiftDepartment(lhs.departement, organizationDepartment);
    const rhsDepartment = shiftDepartment(rhs.departement, organizationDepartment);
    if (lhsDepartment !== rhsDepartment) {
      return lhsDepartment - rhsDepartment;
    }
    return lhs.name.localeCompare(rhs.name);
  });

  let healthServicesForCurrentStructure = [];
  if (healthStructureId != null) {
    healthServicesForCurrentStructure = (healthServicesByStructure.get(healthStructureId) || []).map(h => {
      const isDisabled = typeof h.disabled_at === 'string';
      const hasSupprShortName = h.short_name?.includes(NAMING_A_SUPPR);
      const hasSupprName = h.nom?.includes(NAMING_A_SUPPR);
      const newName = hasSupprName ? h.nom.replace(NAMING_HS_A_SUPPR, '') : h.nom;
      const newShortName = hasSupprShortName ? h.short_name.replace(NAMING_HS_A_SUPPR, '') : h.short_name;
      return {
        value: h.id,
        label: `${newShortName || newName}${isDisabled || hasSupprName || hasSupprShortName ? NAMING_HS_DELETE : ''}`,
      };
    });
  }

  const healthStructureOptions = healthStructures.map(h => {
    const isDisabled = typeof h.disabled_at === 'string';
    const hasSupprName = h.name?.includes(NAMING_A_SUPPR);
    const newName = hasSupprName ? h.name.replace(NAMING_HS_A_SUPPR, '') : h.name;
    return {
      value: h.id,
      label: `[${h.departement}] ${newName}${isDisabled || hasSupprName ? NAMING_HS_DELETE : ''}`,
    };
  });

  return {
    healthStructureOptions:
      enCours === 'yes'
        ? healthStructureOptions.filter(hs => !hs.label.includes(NAMING_HS_DELETE))
        : healthStructureOptions,
    healthServicesByStructure:
      enCours === 'yes'
        ? new Map(
          [...healthServicesByStructure].filter(
            ([_, v]) => !v.label?.includes(NAMING_HS_DELETE) || !v.name?.includes(NAMING_HS_DELETE),
          ),
        )
        : healthServicesByStructure,
    healthServicesForCurrentStructure:
      enCours === 'yes'
        ? healthServicesForCurrentStructure.filter(hs => !hs.label.includes(NAMING_HS_DELETE))
        : healthServicesForCurrentStructure,
  };
};

/**
 * Adds the information contained in b to each entry of iv_fluids or medications
 * @param {Array}   a     bilan.injections.iv_fluids || bilan.injections.medications
 * @param {Object}  b     bilan.injections
 */
export const addInfoInject = (a, b) => {
  a.forEach(e => {
    const time = DateTime.fromISO(e.injected_at).toLocaleString(DateTime.TIME_24_SIMPLE).split(':');
    e.heure = time[0];
    e.minute = time[1];
    e.comment = b.comment;
    e.route_of_administration = b.route_of_administration;
    e.type = 'injection';
  });
  return a;
};

/** Sorts monitors - First by hours, then by minutes */
export const sortSurv = s => {
  s.sort((a, b) => {
    if (a.heure < b.heure) return -1;
    if (a.heure > b.heure) return 1;

    if (a.minute < b.minute) return -1;
    if (a.minute > b.minute) return 1;

    return 0;
  });
};

/** Permet de générer un objet du bilan en vue d'une mise à jour de celui-ci */
export const generatePayloadBilan = (bilanScope, bilan, enCours, hopitalId, numeroDossier, numeroVictime) => {
  let lesionnel_V2 = {};
  if (bilan.lesionnel_avant_v2) lesionnel_V2 = { v2_lesionnel_avant: bilan.lesionnel_avant_v2 };
  if (bilan.lesionnel_arriere_v2) lesionnel_V2 = { ...lesionnel_V2, v2_lesionnel_arriere: bilan.lesionnel_arriere_v2 };
  let ssoSmp = {};
  if (bilanScope === 'SSO_SMP') {
    ssoSmp = {
      sso_intervention: {
        context: {
          ...bilan?.sso_context,
          excel_action_synthese: [...bilan?.sso_context?.excel_action_synthese] || [],
          excel_contact_mad: [...bilan?.sso_context?.excel_contact_mad] || [],
          excel_person_in_place: [...bilan?.sso_context?.excel_person_in_place] || [],
        },
        smp: bilan?.sso_smp || {},
        sso_examens: [...bilan?.sso_examens] || [],
        smp_examens: [...bilan?.smp_examens] || [],
      },
    };
  }
  return {
    type: bilanScope, // "SSO_SMP" || "BILAN"
    fiche_bilan: {
      bilan: {
        en_cours: enCours,
        simplifie: bilan.simplifie,
        priorisation: bilan.priorisation,
        priorisation_v2: bilan.priorisation_v2,
      },
      fiche: {
        respiration: bilan.respiration,
        geste: {
          ...bilan.geste,
          medics: bilan.medicaments,
        },
        circonstance: bilan.circonstance,
        victime: bilan.victime,
        circulatoire: bilan.circulatoire,
        neurologique: bilan.neurologique,
        devenir: {
          ...bilan.devenir,
          hopital_id: hopitalId,
        },
        trauma: {
          ...bilan.lesionnel,
          dummy: {
            lesionnel_avant: bilan.lesionnel_avant,
            lesionnel_arriere: bilan.lesionnel_arriere,
            ...lesionnel_V2,
          },
        },
        surveillances: bilan.surveillances,
        obstetrique: {
          ...bilan.obstetrique,
          excel_apgar: bilan?.excel_apgars || [],
        },
        medic_info: bilan.medic_info,
        nihss: bilan.nihss || {},
        acr: bilan.acr || {},
        douleur_thoracique: bilan.douleur_thoracique || {},
        mort_inattendue: bilan.mort_inattendue || {},
        transfert_pedia: bilan.transfert_pedia || {},
        // multiconstants: bilan.multiconstants || {},
        // x collision: bilan.fiche_collision,
        // x proces_verbal: bilan.proces_verbals,
        // x refus_transport_soins: bilan.refus_transport_soins,
        // x ircs: bilan.ircs_sheet,
        // relevage: bilan.relevage || {},
        burn_surface: bilan.burn_surface || {},
        status: bilan.status || {},
        crss: bilan.crss || {},
        // traces_indices: bilan.traces_indices || {},
      },
      equipe: bilan.equipe,
      equipage_plaintext: bilan.equipage_plaintext || {},
    },
    info_tech: {
      ...bilan.info_tech,
      conformite: numeroDossier,
      conformite_id: numeroVictime,
    },
    ...ssoSmp,
  };
};

export const MEDIC_DRUG_ROUTES_CODE = [
  ['CHEW'],
  ['EXTCORPDIF'],
  ['HEMODIFF'],
  ['TRNSDERMD'],
  ['DISSOLVE'],
  ['SL'],
  ['DOUCHE'],
  ['ELECTOSMOS'],
  ['ENEMA'],
  ['RETENEMA'],
  ['IVFLUSH'],
  ['GARGLE'],
  ['SOAK'],
  ['IDIMPLNT'],
  ['IVITIMPLNT'],
  ['SQIMPLNT'],
  ['EPI'],
  ['IA'],
  ['IC'],
  ['ICOR'],
  ['IOSSC'],
  ['IT'],
  ['IVASCINFUS'],
  ['IV'],
  ['IVC'],
  ['IVCC'],
  ['IVCI'],
  ['PCA'],
  ['SQINFUS'],
  ['IPPB'],
  ['NASINHL'],
  ['NASINHLC'],
  ['NEB'],
  ['NASNEB'],
  ['ORNEB'],
  ['ORIFINHL'],
  ['REBREATH'],
  ['ORINHL'],
  ['TRACH'],
  ['VENT'],
  ['VENTMASK'],
  ['AMNINJ'],
  ['BILINJ'],
  ['CERVINJ'],
  ['ENDOSININJ'],
  ['EPIDURINJ'],
  ['EPIINJ'],
  ['EPINJSP'],
  ['EXTRAMNINJ'],
  ['EXTCORPINJ'],
  ['CHOLINJ'],
  ['GBINJ'],
  ['GINGINJ'],
  ['HEMOPORT'],
  ['IPUMPINJ'],
  ['INTERMENINJ'],
  ['INTERSTITINJ'],
  ['IABDINJ'],
  ['IAINJ'],
  ['IAINJP'],
  ['IAINJSP'],
  ['IARTINJ'],
  ['IBURSINJ'],
  ['ICARDINJ'],
  ['ICARINJP'],
  ['ICARDINJRP'],
  ['ICARDINJSP'],
  ['ICARTINJ'],
  ['ICAUDINJ'],
  ['ICAVINJ'],
  ['ICAVITINJ'],
  ['ICEREBINJ'],
  ['IUINJC'],
  ['ICISTERNINJ'],
  ['ICORONINJ'],
  ['ICORONINJP'],
  ['ICORPCAVINJ'],
  ['IDINJ'],
  ['IDISCINJ'],
  ['IDUCTINJ'],
  ['IDURINJ'],
  ['IEPIDINJ'],
  ['IEPITHINJ'],
  ['ILESINJ'],
  ['ILUMINJ'],
  ['ILYMPJINJ'],
  ['IMEDULINJ'],
  ['IM'],
  ['IMD'],
  ['IMZ'],
  ['IOINJ'],
  ['IOSSINJ'],
  ['IOVARINJ'],
  ['IPCARDINJ'],
  ['IPERINJ'],
  ['IPLRINJ'],
  ['IPROSTINJ'],
  ['IPINJ'],
  ['ISINJ'],
  ['ISTERINJ'],
  ['ISYNINJ'],
  ['ITENDINJ'],
  ['ITESTINJ'],
  ['ITINJ'],
  ['ITHORINJ'],
  ['ITUBINJ'],
  ['ITUMINJ'],
  ['ITYMPINJ'],
  ['IURETINJ'],
  ['IUINJ'],
  ['IVASCINJ'],
  ['IVINJ'],
  ['IVINJBOL'],
  ['IVPUSH'],
  ['IVRPUSH'],
  ['IVSPUSH'],
  ['IVENTINJ'],
  ['IVESINJ'],
  ['IVITINJ'],
  ['PNSINJ'],
  ['PARENTINJ'],
  ['PAINJ'],
  ['PDURINJ'],
  ['PNINJ'],
  ['PDONTINJ'],
  ['PDPINJ'],
  ['RBINJ'],
  ['SOFTISINJ'],
  ['SUBARACHINJ'],
  ['SCINJ'],
  ['SQ'],
  ['SLESINJ'],
  ['SUBMUCINJ'],
  ['TRPLACINJ'],
  ['TRTRACHINJ'],
  ['URETINJ'],
  ['URETHINJ'],
  ['BLADINJ'],
  ['CERVINS'],
  ['IOSURGINS'],
  ['IU'],
  ['LPINS'],
  ['PR'],
  ['SQSURGINS'],
  ['URETHINS'],
  ['VAGINSI'],
  ['CECINSTL'],
  ['CTINSTL'],
  ['CAPDINSTL'],
  ['ETINSTL'],
  ['ENTINSTL'],
  ['EFT'],
  ['GJT'],
  ['GT'],
  ['IBRONCHINSTIL'],
  ['IDUODINSTIL'],
  ['IESOPHINSTIL'],
  ['IGASTINSTIL'],
  ['IILEALINJ'],
  ['IOINSTL'],
  ['ISININSTIL'],
  ['ITRACHINSTIL'],
  ['IUINSTL'],
  ['JJTINSTL'],
  ['LARYNGINSTIL'],
  ['NASALINSTIL'],
  ['NASOGASINSTIL'],
  ['NGT'],
  ['NTT'],
  ['OGT'],
  ['OJJ'],
  ['OT'],
  ['PNSINSTL'],
  ['PDPINSTL'],
  ['RECINSTL'],
  ['RECTINSTL'],
  ['SININSTIL'],
  ['SOFTISINSTIL'],
  ['TRACHINSTL'],
  ['TRTYMPINSTIL'],
  ['URETHINSTL'],
  ['BLADINSTL'],
  ['INSUF'],
  ['GUIRR'],
  ['IGASTIRR'],
  ['ILESIRR'],
  ['IOIRR'],
  ['RECIRR'],
  ['BLADIRR'],
  ['BLADIRRC'],
  ['BLADIRRT'],
  ['IGASTLAV'],
  ['IDOUDMAB'],
  ['ITRACHMAB'],
  ['SMUCMAB'],
  ['ETNEB'],
  ['OCDRESTA'],
  ['DENRINSE'],
  ['ORRINSE'],
  ['SHAMPOO'],
  ['SUBCONJTA'],
  ['SUCK'],
  ['URETHSUP'],
  ['PO'],
  ['SWISHSPIT'],
  ['SWISHSWAL'],
  ['TOPICA'],
  ['TTYMPTABSORP'],
  ['BUC'],
  ['CERV'],
  ['DEN'],
  ['GIN'],
  ['HAIR'],
  ['ICORNTA'],
  ['ICORONTA'],
  ['IESOPHTA'],
  ['IILEALTA'],
  ['ILTOP'],
  ['ILUMTA'],
  ['IOTOP'],
  ['IONTO'],
  ['LARYNGTA'],
  ['MUC'],
  ['NAIL'],
  ['NASAL'],
  ['OPTHALTA'],
  ['ORALTA'],
  ['ORMUC'],
  ['OROPHARTA'],
  ['PERIANAL'],
  ['PERINEAL'],
  ['PDONTTA'],
  ['RECTAL'],
  ['SCALP'],
  ['SKIN'],
  ['DRESS'],
  ['SWAB'],
  ['TMUCTA'],
  ['VAGINS'],
  ['TRNSDERM'],
  ['TRNSLING'],
];
